import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo.png";
import "../assets/css/complaints.css";
import { FaHome } from 'react-icons/fa';
import SentimentApi from '../api/SentimentApi';
import LoadingBar from 'react-top-loading-bar';
import Slider from '@mui/material/Slider';
import cabs from "../assets/images/cabs.png";
import { retrieveObjectData, storeObjectData } from './Util';

const CustomerSatisfactionScore = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const oldData = retrieveObjectData();
  const [isUSerRegistered, setIsUSerRegistered] = useState(false);
  const [stateLoading, setStateLoading] = useState(true);
  const [firstname, onfirstnameChange] = useState('');
  const [lastname, onlastnameChange] = useState('');
  const [phone, onphoneChange] = useState('');
  const [email, onemailChange] = useState('');
  const [policy, onPolicyChange] = useState('');
  const [value, setValue] = useState(null);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(1);
  const [id, setID] = useState("");
  const [buvalue, setbuValue] = useState(null);
  const [buopen, setBuOpen] = useState(false);
  const [bus, setBU] = useState([
    { label: 'CABS', value: 1 },
    { label: 'Old Mutual Life Assurance', value: 2 },
    // { label: 'Retail', value: 3 },
    { label: 'Old Mutual Unit Trusts', value: 4 },
    { label: 'Old Mutual Short-term Insurance', value: 5 },
    { label: 'Old Mutual Finance', value: 6 },
    { label: 'Old Mutual Investments', value: 7 },
    // { label: 'Group Shared Services', value: 9 },
    { label: 'Old Mutual Digital Services (O\’mari)', value: 10 },
  ]);

  const [state, setState] = useState({});

  const handleChange = (e) => {
    const newData = { ...state, [e.target.name]: e.target.value };
    setState(newData);
    storeObjectData(newData);
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    businessUnit: '',
    rating: 1,
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRatingSubmission = () => {
    setLoading(true);
    ref.current.continuousStart();
    const postData = {
      "complaint": rating,
      "product_id": buvalue,
      "category_id": 19,
      "channel_id": 3,
      tablet_id: 105,
      phone: state.countryCode + phone,
      firstname: firstname,
      lastname: lastname,
      email: email,
      id_number: id,
      policy: policy
    };

    SentimentApi.postSatisfaction(postData, token).then((response) => {
      ref.current.complete();
      navigate("/success");
      setLoading(true);
    }).catch((error) => {
      setLoading(true);
    });
  }

  const goBack = () => {
    navigate('/')
  };

  const cabsSite = !window.location.origin.includes("cabs");

  return (
    <div className="feedback-screen">
      <LoadingBar color='green' progress={5} ref={ref} />
      <div className='feedback-bg'></div>
      <img style={{ width: '150px' }} src={window.location.origin.includes("cabs") ? cabs : logo} alt="logo" />
      <div className="top-container">
      <p style={{ color: '#000', fontSize: 12, marginTop: 10, cursor: 'pointer' }}>
          <a href='/' style={{ textDecoration: 'none', color: '#000' }}><span style={{ color: 'blue' }}>Home</span></a> ~ CSS
        </p>
        <h1 className="text" style={{ color: '#000' }}>Customer Satisfaction Score</h1>
        <p className="textbelow">
          On a scale of 1 to 10, how satisfied are you with our services ?
        </p>
      </div>
      <div className="bottom-container">

        <input
          type="text"
          placeholder="First Name"
          value={firstname}
          name="firstname"
          onChange={(e) => {
            onfirstnameChange(e.target.value);
            handleChange(e);
          }}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastname}
          name="lastname"
          onChange={(e) => {
            onlastnameChange(e.target.value);
            handleChange(e);
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input type="text" name="countryCode" value={state.countryCode} onChange={handleChange} placeholder="+263" style={{ width: '15%' }} />
          <input
            type="text"
            placeholder="Phone Number"
            value={phone}
            name="phone"
            onChange={(e) => {
              onphoneChange(e.target.value)
              handleChange(e);
            }}
          />
        </div>
        <input
          type="text"
          placeholder="Account/Policy Number"
          value={policy}
          name="policy"
          onChange={(e) => {
            onPolicyChange(e.target.value);
            handleChange(e);
          }}
        />
        <input
          type="text"
          placeholder="Email"
          value={email}
          name="email"
          onChange={(e) => {
            onemailChange(e.target.value)
            handleChange(e);
          }}
        />
        <p style={{ marginLeft: 10, marginTop: 10, marginBottom: 0 }}>Please Select Business Unit</p>
        <select className='option'
          onChange={(x) => setbuValue(x.target.value)}>
          <option>Select item</option>
          {
            bus.map((x) => {
              return <option value={x.value}>{x.label}</option>
            })
          }
        </select>
        <label id='rating' className='ratingLabel'>Select Rating</label>
        <label className='ratingLabelMark' style={{ color: rating >= 5 ? '#127B01' : '#ffa500' }}>{rating}/10</label>
        <div className="bottomContainer" style={{ width: '100%' }}>
          <div className='slider'>
            <Slider
              name="rating"
              aria-label="Small steps"
              defaultValue={1}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={10}
              color='success'
              value={rating}
              onChange={(x) => setRating(x.target.value)}
            />
          </div>
        </div>

        <button
          disabled={loading}
          style={{ backgroundColor: cabsSite ? "#00763D" : "#2e3f77" }}
          className="button" onClick={handleRatingSubmission}>
          {
            loading ? <span class="loader"></span> : <span className="submitText">SUBMIT</span>
          }
        </button>
        <div className="buttonContainer">
          <div className="goBack" onClick={goBack}>
            <FaHome className="goBackIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSatisfactionScore;
